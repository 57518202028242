<template>
  <v-row justify="center">
      <v-dialog    
          v-model="show"
          persistent
          max-width="500"
          overlay-opacity="1"
      >
        <v-card>
          <v-card-text style="height: 120px" class="pt-4">
            <!-- <div class="ma-6 pa-0 text-center">
              <img
                src="@/assets/img/alertbell_mobile.png"
                width="100px"
                height="100px"
                alt="One Box"
              />
            </div> -->
            <!-- <div class="text-center" style="font-size: 13px;color:#333333">
              <h2>เรียนผู้ใช้งาน One Box ทุกท่าน</h2>
            </div> -->
            <div class="text-center">
              <br />
              <span style="font-size: 16px;" class="ml-4">
                <!-- การแก้ไขออนไลน์เป็นการบันทึกทับไฟล์เดิม ไม่สามารถบันทึกเป็นเวอร์ชั่นใหม่ได้ -->
                {{ $t("onlyoffice.alert_word") }}
              </span>   
              <br/>
  
            </div>
          </v-card-text>
          <v-card-text class="text-center ml-0">
            <v-btn block color="#184966" class="white--text" style="font-size: 16px;" @click="checkdialog()"
              >{{ $t("onlyoffice.confirm") }}</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
  </v-row>
</template>
<script>
import VueCookies from "vue-cookies";
export default {
  props: ["show"],
  data: function() {
    return {
      // checkshow: false,
      // dialog: false,
    }
  },
  methods:{
    checkdialog(){
        this.$emit('closedialog')
    },
    
    
  }
}
</script>